<template>
  <div class="container">
    <q-btn label="Dismiss"
           class="close-button"
           color="grey-7"
           rounded
           size="sm"
           @click="$emit('close')"
    />
    <span class="container__title">
      OQL Examples
    </span>
    <div class="example-container">
      <div v-for="e in examples"
           :key="e.title"
           class="example"
      >
        <span class="example__title">
          <q-btn rounded
                 class="example__button"
                 label="Try it"
                 color="dark"
                 size="sm"
                 @click="$emit('input', e.oql)"
          />{{ e.title }}
        </span>
        <span class="example__description">
          {{ e.description }}
        </span>
        <span class="example__oql">
          {{ e.oql }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'QueryHelper',
  data() {
    return {
      examples: [
        {
          title: 'All temperature data',
          description: 'Get the temperature values from every sensors since one day. Results are limited to 50.',
          oql: `SELECT data.temperature FROM ROOT-0000-0000-0000-000000000000 WHERE (data.*.time >  '2018-11-14T14:29:00Z' AND data.*.time < '2018-11-15T14:29:00Z' ) LIMIT 50;`,
        },
        {
          title: 'All temperature and humidity data',
          description: 'Get the temperature and humidity values from every sensors since one day. Results are limited to 50.',
          oql: `SELECT data.temperature, data.humidity FROM ROOT-0000-0000-0000-000000000000 WHERE (data.*.time >  '2018-11-14T14:29:00Z' AND data.*.time < '2018-11-15T14:29:00Z' ) LIMIT 50;`,
        },
        {
          title: 'Rooms with temperature greater than',
          description: 'Get the meeting rooms where the temperature is more than 25°C during last day.',
          oql: `SELECT places.room FROM ROOT-0000-0000-0000-000000000000 WHERE ( data.*.time > '2018-11-14T15:15:00Z' AND data.*.time < '2018-11-15T15:15:00Z' ) AND ( data.temperature.air > 25 ) ;`,
        },
      ],
    };
  },
};
</script>

<style lang="stylus" scoped>
@import '~variables'

.container
    height 100%
    background-color white
    border-radius 5px
    padding 10px 10px 20px 10px
    box-shadow 0px 5px 10px 0px #9c9c9c
    margin 0 auto
    position relative
    width 95%
    .close-button
        position absolute
        right 10px
        top 10px
    .container__title
        text-align left
        width 100%
        font-weight 700
        font-size $fs-h1
        color black
    .example-container
        display flex
        flex-direction column
        .example
            margin 30px 0 0 10px
            display flex
            flex-direction column
            .example__title
                font-size $fs-h2
                color black
                font-weight 700
                .example__button
                    margin-right 10px
            .example__description
                font-size $fs-h3
                margin 5px 0 0 5px
                color $dark
            .example__oql
                margin-top 15px
                background-color $light
                border-radius 3px
                padding 10px
</style>
