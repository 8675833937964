<!--eslint-disable vue/attribute-hyphenation -->
<template>
  <div :class="sidebarItemClass"
       class="sidebar-item">
    <div :collapsed="collapsed"
         class="header"
         @click="toggleCollapse">
      <img v-if="iconUrl"
           :src="iconUrl"
           class="header__icon">
      <h3 class="header__title">
        {{ title }}
      </h3>
      <q-tooltip v-if="isTooltip && subText.length>1"
                anchor="bottom left"
                class="tooltip"
                self="top left"
                :offset="[-20, 10]">
          <div style="max-width: 200px">
            {{ subText }}
          </div>
      </q-tooltip>
      <span class="sub-text">
        {{ subText }}
      </span>
      <button v-if="actionButton && !collapsed"
              class="header__button o-button o-button--hover"
              @click.stop="$emit('onClickActionButton')">{{actionButton}}</button>
    </div>
    <collapseContainer :pose="collapsed ? 'hidden' : 'visible'"
                       class="collapse-container">
      <div v-show="hasSlot"
           class="main">
        <slot />
      </div>
    </collapseContainer>
  </div>
</template>

<script>
import posed from 'vue-pose';

export default {
  name: 'SidebarItem',
  components: {
    collapseContainer: posed.div({
      visible: {
        height: 'auto',
        applyAtEnd: { overflow: 'visible' },
      },
      hidden: {
        height: '0px',
        applyAtStart: { overflow: 'hidden' },
      },
    }),
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    subText: {
      type: String,
      default: '',
    },
    iconUrl: {
      type: String,
      default: null,
    },
    main: {
      type: Boolean,
      default: false,
    },
    collapsed: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: false,
    },
    actionButton: {
      type: String,
      default: null,
    },
    isTooltip: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      test: false,
    };
  },
  computed: {
    hasSlot() {
      return this.$slots.default;
    },
    sidebarItemClass() {
      return {
        'sidebar-item--main': this.main,
        'sidebar-item--small': this.small,
        'sidebar-item--collapsed': this.collapsed,
      };
    },
  },
  methods: {
    toggleCollapse() {
      this.collapsed = !this.collapsed;
      this.$emit('expand', !this.collapsed);
    },
  },
};
</script>

<style lang="stylus" scoped>
@import '~variables'

$sidebar-header-size = 50px

.sidebar-item
  display flex
  flex-direction column
  border-bottom $grid-border
  &--small
    .header
      padding 12px 25px 12px 12px
    .header__title
      font-weight 400
      font-size $fs-h2
  &--collapsed
    flex-shrink 0
    border-bottom none
    .header__title
      color lighten($dark, 30%) !important
  .header
    display flex
    flex-shrink 0
    padding $space-1
    height $sidebar-header-size+30
    border-bottom $grid-border
    background-color white
    cursor pointer
    user-select none
    flex-direction column
    &:hover
      .header__title
        color $black !important
    .header__icon
      margin-right 8px
      width 30px
      height 30px
    .header__title
      color $black
      text-transform uppercase
      font-weight 300
      font-size $nfs-big
    .header__button
      margin-left auto
      border-radius $border-radius-rounded
  .collapse-container
    display flex
    flex-direction column
  .main
    display flex
    background linear-gradient($light 10px, white 100px)

.sub-text
  margin-left $space-2
  font-weight 200
  font-size $fs-h1
  text-overflow ellipsis
  overflow hidden
  white-space nowrap

.sidebar-item--main
  .sidebar-item__header, .sidebar-item__main
    background-color $light
</style>
