<template>
  <div style="height: 100%">
    <q-layout ref="layout"
              class="building-facts">
      <q-layout-drawer :width="709"
                       :value="true"
                       class="left-sidebar no-transition"
                       side="left">
        <SidebarItem class="sidebar-item"
                     title="Oasis Query Language">
          <q-icon class="helper-shortcut"
                  name="far fa-question-circle"
                  size="20px"
                  color="grey-8"
                  @click.native="openHelp = !openHelp" />
          <div class="input-container">
            <textarea v-model="userQuery"
                      autocomplete="off"
                      autocorrect="off"
                      spellcheck="false"
                      class="sidebar__query"
                      rows="20"
                      placeholder="Write your query..." />
            <q-btn label="send"
                   outline
                   color="black"
                   class="sidebar__button"
                   @click="send()"
            />
          </div>
        </SidebarItem>
      </q-layout-drawer>
      <q-page-container class="building-facts__center">
        <div class="viewer">
          <transition name="fade">
            <query-helper v-if="!loading && !formattedData && openHelp || openHelp"
                          @input="val => userQuery = val"
                          @close="openHelp = false"
            />
          </transition>
          <div v-if="!loading && !openHelp"
               class="column justify-around"
          >
            <div v-for="c in formattedData"
                 :key="c.name"
            >
              <q-table :binaryStateSort="true"
                       :title="c.label"
                       :columns="c.columns"
                       :data="c.data"
                       rowKey="time"
                       class="results-table"
                       dense
                       color="black"
              />
            </div>
          </div>
          <qt-spinner v-if="loading"
                      size="80"
                      class="spinner-loader"
          />
        </div>
      </q-page-container>
    </q-layout>
  </div>
</template>

<script>
import QtSpinner from '@/app/components/ui/spinner.vue';
import { mapGetters, mapState, mapActions, mapMutations } from 'vuex';
import SidebarItem from '@/app/components/layout/sidebar/sidebar-item.vue';
import QueryHelper from '@/app/pages/oql/components/display/query-helper.vue';
export default {
  name: 'QueryBuilder',
  components: {
    QtSpinner,
    SidebarItem,
    QueryHelper,
  },
  data() {
    return {
      userQuery: '',
      openHelp: true,
    };
  },
  computed: {
    ...mapState('oql', ['loading', 'query', 'oqlError']),
    ...mapGetters('oql', ['formattedData']),
  },
  watch: {
    oqlError(val) {
      if (val)
        this.$q.notify({
          message: val,
          type: 'warning',
          textColor: 'black',
          icon: 'warning',
          position: 'top',
          timeout: 0,
          actions: [
            {
              label: 'Dismiss',
              handler: () => {},
            },
          ],
        });
    },
    formattedData(val) {
      if (val) this.openHelp = false;
    },
  },
  mounted() {
    this.fetchTopology();
    this.buildQuery();
    this.userQuery = this.query;
  },
  methods: {
    ...mapActions('oql', ['fetchTopology', 'buildQuery', 'sendQuery']),
    ...mapMutations('oql', {
      setQueryMode: 'SET_QUERY_MODE',
      setQuery: 'SET_QUERY',
    }),
    send() {
      this.setQueryMode(true);
      this.setQuery(this.userQuery);
      this.sendQuery();
    },
  },
};
</script>

<style lang="stylus" scoped>
@import '~variables'

.building-facts
  padding 0 !important
  min-height auto
  height 100%
  .left-sidebar >>> aside
    position fixed
    top 50px
    overflow visible // to see the drawer
    padding 25px
    box-shadow $shadow-elevation-3
    .sidebar-item
      position relative
      .helper-shortcut
        position absolute
        top 60px
        right 6px
        cursor pointer
      .input-container
        display flex
        flex-direction column
        align-items center
        .sidebar__query
          padding 18px
          width 100%
          outline none
          border solid 3px $silver
          background-color $light
          text-align justify
          font-size $fs-h2
          line-height 27px
          resize none
          .sidebar__query textarea
            color black
        .sidebar__button
          margin-top 15px
          width 25%
  .left-sidebar.no-transition >>> aside
    transform translateX(0px)
  .sidebar-out
    z-index 2

.viewer
  position relative
  padding 10px
  .results-table
    margin 10px auto
    background-color white
  .spinner-loader
    position absolute
    top 300px
    left 50%
    margin-left -40px

::-webkit-scrollbar
  width 6px

::-webkit-scrollbar-track
  background #cacaca

::-webkit-scrollbar-thumb
  background #828282

::-webkit-scrollbar-thumb:hover
  background #000

.fade-enter-active, .fade-leave-active
  opacity 1
  transition all 200ms ease

.fade-enter, .fade-leave-to
  opacity 0
  transition all 200ms ease
</style>
